import {
  CREATE_DONATION_REQUEST,
  CREATE_DONATION_SUCCESS,
  CREATE_DONATION_FAILURE,
  FETCH_DONATION_REQUEST,
  FETCH_DONATION_SUCCESS,
  FETCH_DONATION_FAILURE,
  PATCH_DONATION_REQUEST,
  PATCH_DONATION_SUCCESS,
  PATCH_DONATION_FAILURE,
} from './donationTypes';

const initialState = {
  loading: false,
  donation: null,
  error: '',
};

const donationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_DONATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_DONATION_SUCCESS:
      return {
        ...state,
        loading: false,
        donation: action.payload
      };
    case CREATE_DONATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_DONATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DONATION_SUCCESS:
      return {
        ...state,
        loading: false,
        donation: action.payload,
        error: '',
      };
    case FETCH_DONATION_FAILURE:
      return {
        ...state,
        loading: false,
        donation: null,
        error: action.payload,
      };
      case PATCH_DONATION_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case PATCH_DONATION_SUCCESS:
        return {
          ...state,
          loading: false,
          donation: action.payload,
          error: '',
        };
      case PATCH_DONATION_FAILURE:
        return {
          ...state,
          loading: false,
          donation: null,
          error: action.payload,
        };
    default:
      return state;
  }
};

export default donationReducer;